<template>
  <div class="historyRecord">
    <Header title="观看记录" />
    <van-tabs
      v-model="activeName"
      background="#fff"
      title-active-color="rgb(255,103,143)"
      title-inactive-color="rgb(51,51,51)"
      class="vant_tabs"
      animated
      :swipeable="true"
      @change="changeTab"
    >
      <van-tab :title="item.name" v-for="(item, index) in tabs" :key="index" :name="item.path">
        <keep-alive>
          <router-view ref="currentView" v-if="activeName == item.path" class="tab_view" :isEdit="isEdit" @close="close" />
        </keep-alive>
      </van-tab>
    </van-tabs>
    <div class="delBtnBox" :class="{ activeDelBtnBox: isEdit }">
      <div class="selectAll" @click="selectAll">
        <svg-icon class="editBtn" iconClass="batchSelected" v-if="isSelectAll" />
        <svg-icon class="editBtn" iconClass="batchUnSelect" v-else />
        <span>全选</span>
      </div>
      <div class="verticalLine1"></div>
      <div class="del" @click="delBtn">
        <span>删除</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import Header from '@/components/Header';

export default {
  name: 'HistoryRecord',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Header,
  },
  data() {
    return {
      activeName: '',
      tabs: [
        {
          path: '/historyMovies',
          name: '长视频',
        },
        {
          path: '/historyVideo',
          name: '短视频',
        },
      ],
      isEdit: false,
      isSelectAll: false, // 是否全选
    };
  },
  methods: {
    edit() {
      if (this.$refs.currentView[0].list.length == 0) return;
      this.isEdit = !this.isEdit;
    },
    changeTab(name) {
      this.isEdit = false;
      this.$router.replace({ path: name, query: { id: this.$route.query.id } });
    },
    // 选择全部
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.$refs.currentView[0].selectAll(this.isSelectAll);
    },
    // 删除
    delBtn() {
      this.$refs.currentView[0].batchDel();
    },
    close() {
      this.isEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.historyRecord {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      .edit {
        padding: 5px;
      }
    }
  }

  /deep/ .van-tabs {
    .van-tabs__wrap {
      padding: 0 20px;
      width: 140px;
    }
    .van-tab__text {
      font-size: 14px;
    }
    .van-tab--active {
      font-weight: 600;
    }
    .van-tabs__line {
      width: 22px;
      height: 8px;
      background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      bottom: 20px;
    }
  }

  .delBtnBox {
    height: 60px;
    width: 100%;
    position: fixed;
    left: 0px;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    transform: translateY(60px);
    transition: transform 0.5s;
    .selectAll {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .editBtn {
        width: 22px;
        height: 22px;
      }
      span {
        margin-left: 5px;
      }
    }
    .verticalLine1 {
      height: 35px;
      width: 1px;
      background-color: rgb(205, 212, 223);
    }
    .del {
      flex: 1;
      color: rgb(255, 103, 143);
      text-align: center;
    }
  }
  .activeDelBtnBox {
    transform: translateY(0px);
    transition: transform 0.3s;
  }
}
</style>
